import React from 'react' 
import JotformEmbed from 'react-jotform-embed'

const PageRequestServiceSection = () => (
  <div className="bg-gray-100">
    <div className="lg:w-10/12 mx-auto py-20 sm:py-6 sm:px-4 md:py-12 md:px-8">
      <h2 className="font-display text-gray-900 text-3xl sm:text-2xl leading-snug">
        If you are a client and you need to request service, please call us at <a href='tel:714-891-8804' className='underline'>(714) 891-8804</a> or email us using the form below. <span className='text-red-600'>Please do not use this form for emergencies</span>.
      </h2>

      <div className='w-9/12 sm:w-full'>
        <JotformEmbed src="https://form.jotform.com/233333897682468" />
      </div>
    </div>
  </div>
)

export default PageRequestServiceSection